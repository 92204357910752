<template>
    <div class="dynamicListWrap">
        <div class="dynamicList">
            <div class="breadNav">
                    您的位置：
                    <span @click="goHome">首页</span> 
                     > 最新动态       
            </div>
            
            <div class="detailMain">
                <div class="detailMain-left">
                    <div class="tit">最新动态</div>
                    <div class="left-listWrap">
                        <dl>
                            <dt @click="godynamicDetail">
                                <img src="" alt="">
                            </dt>
                            <dd>
                                <p @click="godynamicDetail">此处为资讯列表页主标题此处为资讯列表页主标题此处为资讯列表...</p>
                                <p @click="godynamicDetail">此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新闻内容  简介此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新..</p>
                                <div>
                                    <span>2019-02-10</span>
                                    <span>2848人浏览</span>
                                </div>
                            </dd>
                        </dl>
                         <dl>
                            <dt>
                                <img src="" alt="">
                            </dt>
                            <dd>
                                <p>此处为资讯列表页主标题此处为资讯列表页主标题此处为资讯列表...</p>
                                <p>此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新闻内容  简介此处新闻内容简介此处新闻内容简介此处新闻内容简介此简介此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新简介此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新处新</p>
                                <div>
                                    <span>2019-02-10</span>
                                    <span>2848人浏览</span>
                                </div>
                            </dd>
                        </dl>
                         <dl>
                            <dt>
                                <img src="" alt="">
                            </dt>
                            <dd>
                                <p>此处为资讯列表页主标题此处为资讯列表页主标题此处为资讯列表...</p>
                                <p>此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新闻内容  简介此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新..</p>
                                <div>
                                    <span>2019-02-10</span>
                                    <span>2848人浏览</span>
                                </div>
                            </dd>
                        </dl>

                        <!-- 以下是分页 -->
                    <div class="pagtion">
                        <el-pagination
                        @size-change="sizeChangePage"
                        @current-change="currentChangePage"
                        :current-page="page"
                        :page-sizes="[10, 20, 30, 40]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalSize"
                        >
                        </el-pagination>
                    </div>
                    </div>

                    
                </div>


                <div class="detailMain-right">
                    <div class="tit">品牌活动</div>  
                    <div class="rightListWrap">
                        <div class="rightList">
                                <p>此处为资讯列表页主标题此处为资讯列表页主标题此处为资讯列表...</p>
                                <p>此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新闻内容  简介此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新..</p>
                                <div>
                                    <span>2019-02-10</span>
                                </div>
                        </div>
                      
                    </div>               
                </div>
            </div>
        </div>
    </div>
   
</template>

<script>

export default {
    name:"dynamicList",
    data() {
        
        return {
             page: 1, //当前第几页
            rows: 10, //每页默认显示几条
            totalSize:0, //总条数
        };
    },
    methods: {
        godynamicDetail(){
            this.$router.push("/dynamicDetail");
        },
        goHome(){
            this.$router.push("/");
        },
         //以下分页
        //选择每页显示条数触发
        sizeChangePage(val) {
            let self = this;
            self.rows = val;
            // console.log(`每页 ${val} 条`);
            self.getList(self.rows); //请求接口
        },
        //选择第几页触发
        currentChangePage(val) {
        // console.log(`当前页: ${val}`);
            let self = this;
            self.page = val;
            self.getList(self.page);
        },
    },
    }
</script>
<style lang='scss' scoped>
*{
    margin: 0;
    padding: 0;
}
.dynamicListWrap{
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    .dynamicList{
        width: 1200px;
        height: 1000px;
        margin: 0 auto;
        // background: deepskyblue;
        .tit{
            font-size: 18px;
            line-height: 44px;
            color: #333;
        }
        .breadNav {
            padding: 10px 0;
            font-size: 14px;
            :nth-child(1){
                cursor: pointer;
            }
        }

        .detailMain{
            display: flex;
            justify-content:space-between;
            .detailMain-left{
                width: 780px;
                .left-listWrap{
                background: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-left: 20px;
                    padding-right: 20px;
                    dl{
                         padding-top: 20px;
                         padding-bottom: 20px;
                         border-bottom: 1px solid #f0f0f0;
                         display: flex;
                         justify-content: flex-start;
                        dt{
                           width: 240px;
                           height: 140px;
                           flex-shrink: 0;
                           margin-right: 20px;
                           img{
                               display: block;
                               width: 100%;
                               height: 100%;
                           }
                        }
                        dd{
                            p{
                                &:hover{
                                    color: #d82229;
                                    cursor: pointer;
                                }
                            }
                            >:first-child{
                                line-height: 44px;
                                color: #333;
                                margin-bottom: 7px;
                                width: 480px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                font-size: 16px;
                                font-weight: 900;
                            }
                            >:nth-child(2){
                                font-size: 16px;
                                color: #999;
                                line-height:24px; 
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                            }
                            >:nth-child(3){
                                margin-top: 11px;
                                font-size: 16px;
                                color: #999;
                                line-height:26px;

                               >:last-child{
                                   margin-left: 40px;
                                   padding-left: 30px;
                                   background: url("../../assets/icon/yan.png") no-repeat;
                                   background-position: 0 3px;
                               }
                            }
                        }
                    }
                    
                }

                // 以下分页样式
                .pagtion{
                    display: flex;
                    justify-content: center;
                    margin-top: 55px;
                    margin-bottom: 55px;
                }
            }


            .detailMain-right{
                width: 390px;
                .rightListWrap{
                    padding: 20px;
                    background: #fff;
                    padding-top: 10px;
                    .rightList{
                         border-bottom: 1px solid #f0f0f0;
                         padding-bottom: 10px;
                        >:first-child{
                            line-height: 44px;
                            color: #333;
                            margin-bottom: 7px;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 16px;
                            font-weight: 900;
                        }
                        >:nth-child(2){
                            font-size: 16px;
                            color: #999;
                            line-height:24px; 
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }
                        >:nth-child(3){
                            margin-top: 11px;
                            font-size: 16px;
                            color: #999;
                            line-height:26px;
                            
                        }
                     }
                }
            }
        }
    }
}

</style>